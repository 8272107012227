import React, {
    useEffect,
    useState
} from "react";
import { 
    Form
} from 'react-router-dom';
import {
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Input,
    Spacer,
    Textarea
} from "@nextui-org/react";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";

export default function ContactUs({clientInformation}) {
    const [notifications, setNotifications] = useState([]);
    const validatePhoneNumber = (number) => number.replace(/-/g, '').length === 10;

    const validateFields = (data) => {
        const requiredFields = [
            'firstName',
            'lastName',
            'subject',
            'message',
            'phoneNumber',
            'email',
            'selectedTopic'
        ];
        
        return requiredFields.every(field => 
            data.hasOwnProperty(field) 
            && data[field] !== "" 
            && data[field] !== null 
            && data[field] !== undefined
            && typeof data[field] !== 'object'
        );
    }

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        selectedTopic: new Set(['Select a Topic']),
        subject: '',
        message: '',
        ipAddress: clientInformation.ipAddress || 'UNKNOWN'
    });

    useEffect(() => {
        Ajax.request({
            url:"/JudgeAutos/readNotifications",
            success:function(reply) {
                if(reply.data) {
                    setNotifications(reply.data);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === true) {
            setFormData(prevData => ({
                ...prevData,
                firstName: clientInformation.userInformation.firstName || '',
                lastName: clientInformation.userInformation.lastName || '',
                email: clientInformation.userInformation.email || '',
                phoneNumber: clientInformation.userInformation.phoneNumber || '',
            }))
        }
    }, [clientInformation]);

    const handleFormChanges = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTopicChange = (keys) => {
        const selectedTopicString = Array.from(keys).join(', ');
        
        setFormData((prevData) => ({
            ...prevData,
            selectedTopic: selectedTopicString,
        }));
    };

    const sendMessage = () => {
        console.log(formData);
        let fieldErrorTitle = ""
        let fieldErrorText = ""

        if (typeof formData.selectedTopic === "object") {
            fieldErrorTitle = "No Topic Selected"
            fieldErrorText = "Please select a topic"
        } else if (!validatePhoneNumber(formData.phoneNumber)) {
            fieldErrorTitle = "Invalid Phone Number"
            fieldErrorText = "Please enter a 10 digit phone number"
        } else if (!validateFields(formData)) {
            fieldErrorTitle = "Empty Field"
            fieldErrorText = "Please complete all required<span style='color: red;'>*</span> fields"
        }
        
        if (fieldErrorTitle && fieldErrorText) {
            swal.fire({
                title: fieldErrorTitle,
                html: fieldErrorText,
                icon: "error",
                timer: 3000
            })
        } else {
            Ajax.request({
                url:'/JudgeAutos/sendEmailContactUs',
                jsonData: formData,
                success:function(reply) {
                    if(reply.data) {
                        swal.fire({
                            title: "Email sent",
                            text: `Email ${reply.data} sent successfully!`,
                            icon: "success",
                            timer: 3000
                        }).then(() => (
                            window.location.href = "/"
                        ))
                    }
                }
            });
        }
    }

    return(
        <div className="w-full sm:w-1/2 text-center">
            <div className="grid grid-cols-1 gap-4 m-4 p-5 border-solid border-2 rounded-2xl">
                <h1 className="text-5xl">Contact Us</h1>
                <Spacer y={4} />
                <Form className="grid grid-cols-1 gap-2 place-content-center">
                    <div className="grid grid-cols-2 gap-1">
                        <Input
                            isRequired
                            name="firstName"
                            key="firstName"
                            label="First Name"
                            value={formData.firstName}
                            variant="bordered"
                            type="text"
                            onChange={handleFormChanges}
                        />
                        <Input
                            isRequired
                            name="lastName"
                            key="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            variant="bordered"
                            type="text"
                            onChange={handleFormChanges}
                        />
                    </div>
                    <Input
                        isRequired
                        // isInvalid={!validateEmail(formData.email)}
                        name="email"
                        key="email"
                        label="Email"
                        value={formData.email}
                        variant="bordered"
                        type="email"
                        onChange={handleFormChanges}
                    />
                    <Input
                        isRequired
                        // isInvalid={!validatePhoneNumber(formData.phoneNumber)}
                        name="phoneNumber"
                        key="phoneNumber"
                        label="Phone Number"
                        value={formData.phoneNumber}
                        placeholder="10-digit number"
                        variant="bordered"
                        type="number"
                        inputMode="numeric"
                        onChange={handleFormChanges}
                    />
                    <Dropdown>
                        <DropdownTrigger>
                            <Button
                                variant="bordered"
                            >
                                {formData.selectedTopic}
                            </Button>
                        </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Contact Topic Dropdown Menu"
                                selectionMode="single"
                                selectedKeys={new Set([formData.selectedTopic])}
                                onSelectionChange={handleTopicChange}
                            >
                                {notifications.map((item, index) => (
                                    <DropdownItem key={item.notificationName}>
                                        {item.notificationName}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                    </Dropdown>
                    <Input
                        name="subject"
                        key="subject"
                        label="Subject"
                        value={formData.subject}
                        variant="bordered"
                        type="text"
                        onChange={handleFormChanges}
                    />
                    <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                        <Textarea
                            isRequired
                            name="message"
                            key="message"
                            label="Message"
                            value={formData.message}
                            variant="bordered"
                            type="text"
                            onChange={handleFormChanges}
                        />
                    </div>
                    <Button
                        // isDisabled={!validateEmail(formData.email) || !validatePhoneNumber(formData.phoneNumber) || !validateFields(formData)}
                        variant="solid"
                        color="primary"
                        onClick={sendMessage}
                    >
                        Send
                    </Button>
                </Form>
            </div>
        </div>
    );
}