import React, {
    useEffect,
    useState
} from "react";
import { 
    Form,
    useLocation
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    getCurrentDate
} from "../inc/js/Helper"
import {
    Button,
    Input,
    Spacer,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function ClaimEvent({clientInformation}) {
    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        } else {
            localStorage.setItem('salesId', salesId);
            localStorage.setItem('productName', productName);
        }
    }, [clientInformation]);

    const location = useLocation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const salesId = location.state?.salesId || localStorage.getItem('salesId');
    const productName = location.state?.productName || localStorage.getItem('productName');
    const [form, setForm] = useState({
        eventName:"",
        eventDate:"",
        rainoutDate:"",
        eventLocation:""
    });

    function claimEvent() {
        setIsSubmitted(true);

        const formData = {
            salesId:document.getElementById("salesId").value,
            eventName:document.getElementById("eventName").value,
            eventDate:document.getElementById("eventDate").value,
            rainoutDate:document.getElementById("rainoutDate").value,
            eventLocation:document.getElementById("eventLocation").value
        };
        const currentDate = getCurrentDate();
        const emptyData = Object.keys(formData).filter(key => key !== 'rainoutDate').some(key => formData[key].trim() === '');
        
        if (formData.eventDate === currentDate) {
            formData.status = "active"
        } else if (formData.eventDate > currentDate) {
            formData.status = "upcoming"
        } else {
            swal.fire({
                title: "Incorrect date",
                text: "The event date cannot be in the past!",
                icon: "error",
                timer: 1500
            })
            return;
        }

        formData.name = clientInformation.userInformation.firstName + " " + clientInformation.userInformation.lastName
        formData.email = clientInformation.userInformation.email
        formData.phoneNumber = clientInformation.userInformation.phoneNumber

        if (!emptyData) {
            Ajax.request({
                url:"/JudgeAutos/createEvent",
                jsonData:formData,
                success:function(reply) {
                    if(reply) {
                        swal.fire({
                            title:"Event Claimed!",
                            text:"Your event has been claimed!",
                            icon:"success",
                            timer: 1500,
                            showConfirmButton: false,
                            didClose: () => {
                                document.location.href = "/Events?tab=" + formData.status
                            }
                        })
                    } else {
                        swal.fire({
                            title:"Oops!",
                            html:"Something went wrong! <br /><br />Please try again.",
                            icon:"error",
                            didClose: () => {
                                window.location.href="/Events";
                            }
                        });
                    }
                }
            });
        }
    }

    function handleChange(event) {
        let change = {};
        change[event.target.id] = event.target.value;

        setForm({...form, ...change});
    }

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Claim Event</h1>
            <Spacer y={10} />
            <Form onSubmit={claimEvent}>
                <Input
                    isReadOnly
                    id="salesId"
                    label="Sales Id"
                    value={salesId}
                    variant="flat"
                    required={true}
                />
                <Spacer y={1} />
                <Input
                    isReadOnly
                    id="packageName"
                    label="Package"
                    value={productName}
                    variant="flat"
                    required={true}
                />
                <Spacer y={1} />
                <Input
                    id="eventName"
                    label="Event Name"
                    onChange={handleChange}
                    variant="bordered"
                    required={true}
                    isInvalid={isSubmitted && !form.eventName}
                />
                <Spacer y={1} />
                <Input
                    id="eventDate"
                    label="Event Date"
                    onValonChangeueChange={handleChange}
                    type="date"
                    variant="bordered"
                    required={true}
                    isInvalid={isSubmitted && !form.eventDate}
                />
                <Spacer y={1} />
                <Input
                    id="rainoutDate"
                    label="Rainout Date"
                    onChange={handleChange}
                    type="date"
                    variant="bordered"
                    required={false}
                />
                <Spacer y={1} />
                <Input
                    id="eventLocation"
                    label="Event Location"
                    onChange={handleChange}
                    variant="bordered"
                    required={true}
                    isInvalid={isSubmitted && !form.eventLocation}
                />
                <Spacer y={5} />
                <Button 
                    type={"submit"}
                    color="primary"
                    sx={{"margin-top":"1rem"}}
                >
                    Claim Event
                </Button>
            </Form>
        </div>
    );
}