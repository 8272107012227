import React, {useState} from "react";
import Ajax from "../inc/js/Ajax";
import {
    Avatar,
    Button,
    Link,
    Navbar, 
    NavbarBrand, 
    NavbarContent, 
    NavbarItem, 
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem,
    mapPropsVariants,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger
  } from "@nextui-org/react";
  import swal from "sweetalert2";
  import {
    Menu_6_Black,
    Menu_8_Black,
    Menu_alt_3_6_Black
  } from "../inc/images/svgs";

  export default function Header({clientInformation}) {
    function logout() {
        Ajax.request({
            url:"/JudgeAutos/logout",
            success:function() {
                document.location.href = "/";
            }
        });
    }

    const [state, setState] = useState({
        openSideMenu:false,
        openMyAccountMenu:false,
        myAccountMenuAnchor:null
    });

    return(
        <Navbar shouldHideOnScroll isBordered>
            <NavbarBrand className="sm:flex sm:justify-center">
                <Link href="/" color="foreground">
                    <h1 className="text-3xl font-black">JudgeAutos</h1>
                </Link>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarItem>
                    <Link className="text-lg" color="foreground" href="/">
                        Home
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link className="text-lg" color="foreground" href="/Pricing">
                        Pricing
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link className="text-lg" color="foreground" href="/FAQ">
                        FAQ
                    </Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem className="">
                    {!clientInformation.userInformation.loggedIn ?(
                        <div>
                            <Link className="invisible sm:visible text-lg" color="foreground" href="/Login">
                                Log In
                            </Link>
                        <Dropdown className="visible sm:invisible">
                            <DropdownTrigger>
                                <Button
                                    className="visible sm:invisible"
                                    variant="light"
                                >
                                    <Menu_8_Black />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="My account menu">
                                <DropdownItem key="login" href="/" textValue="Home">
                                    <p className="text-xl">Home</p>
                                </DropdownItem>
                                <DropdownItem key="pricing" href="/Pricing" textValue="Pricing">
                                    <p className="text-xl">Pricing</p>
                                </DropdownItem>
                                <DropdownItem key="pricing" href="/FAQ" textValue="FAQ">
                                    <p className="text-xl">FAQ</p>
                                </DropdownItem>
                                <DropdownItem key="contact" href="/ContactUs" textValue="Contact Us">
                                    <p className="text-xl">Contact Us</p>
                                </DropdownItem>
                                <DropdownItem href="/Login" textValue="Log In">
                                    <p className="text-xl">Log In</p>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                   </div>
                    ) : (
                        <Dropdown>
                            <DropdownTrigger>
                                {clientInformation.userInformation.avatar ? (
                                    <Avatar src={clientInformation.userInformation.avatar} style={{
                                        cursor: 'pointer'
                                    }} />
                                ) : (
                                    <Avatar name={clientInformation.userInformation.firstName} style={{
                                        cursor: 'pointer'
                                    }} />
                                )}
                            </DropdownTrigger>
                            <DropdownMenu aria-label="My account menu">
                                <DropdownItem key="myAccount" href="/MyAccount" color="primary" textValue="My Account">
                                    Account
                                </DropdownItem>
                                <DropdownItem key="Events" href="/Events" color="primary" textValue="My Events">
                                    Events
                                </DropdownItem>
                                <DropdownItem key="pricing" href="/Pricing" color="primary" textValue="Pricing" className="sm:hidden">
                                    Pricing
                                </DropdownItem>
                                <DropdownItem key="contact" href="/FAQ" color="primary" textValue="FAQ" className="sm:hidden">
                                    FAQ
                                </DropdownItem>
                                <DropdownItem key="contact" href="/ContactUs" color="primary" textValue="Contact Us">
                                    Contact Us
                                </DropdownItem>
                                <DropdownItem onClick={logout} color="danger" textValue="Log Out">
                                    Log Out
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    );
}