import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { 
    Form,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    Filter_8_Blue,
    ShareIcon_6_Blue,
    Trash_8_Red
} from "../inc/images/svgs";
import {
    getCurrentDate
} from "../inc/js/Helper"
import swal from "sweetalert2";
import {
    Button,
    Divider,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";

export default function Event({clientInformation}) {
    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [clientInformation]);

    useEffect(() => {
        localStorage.setItem('eventId', eventId);

        const handleResize = () => {
            setIsMobile(window.innerWidth < 640);
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();

        Ajax.request({
            url:'/JudgeAutos/readEventDetails?eventId=' + eventId,
            success:function(reply) {
                if(reply.data) {
                    setEventDetails(reply.data);
                    setEventName(reply.data.eventData.find(name => name.eventName).eventName);
                    setEventStatus(reply.data.eventData.find(status => status.status).status);
                    setClassValue(reply.data.classes.length + 1);
                    setJudgeForm(reply.data.eventData.find(form => form.judgeFormId).judgeFormId);
                    setRegistrationForm(reply.data.eventData.find(form => form.registrationFormId).registrationFormId);
                    setClassList(reply?.data?.eventData?.find(name => name.classListName)?.classListName ?? 'noClassList');
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: "Oops!",
                    text: reply,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/Events"
                    }
                })
            }
        });
    }, []);

    const [modalData, setModalData] = useState({});
    const [modalInputValues, setModalInputValues] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        admin: ''
    });
    const [validEmail, setValidEmail] = useState();
    const validateEmail = (email) => email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    const validatePhoneNumber = (number) => number.replace(/-/g, '').length === 10;

    useEffect(() => {
        if (modalData.participantData) {
            setModalInputValues({
                name: modalData.participantData.name,
                phoneNumber: modalData.participantData.phoneNumber,
                email: modalData.participantData.email,
                admin: modalData.participantData.admin
            });
        }
    }, [modalData]);

    useEffect(() => {
        setValidEmail(validateEmail(modalInputValues.email) || modalInputValues.email === '' ? true : false);
    }, [modalInputValues.email]);

    const location = useLocation();
    const navigate = useNavigate();
    const eventId = location.state?.eventId || localStorage.getItem('eventId');
    
    const [classValue, setClassValue] = useState();
    const [eventDetails, setEventDetails] = useState([]);
    const [eventDetailsUpdate, setEventDetailsUpdate] = useState(false);
    const [eventName, setEventName] = useState(null);
    const [eventStatus, setEventStatus] = useState(null);
    const [classList, setClassList] = useState();
    const [isMobile, setIsMobile] = useState();
    const [judgeForm, setJudgeForm] = useState();
    const [registrationForm, setRegistrationForm] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInput, setModalInput] = useState(false);
    const [selectedRegistrationColumns, setSelectedRegistrationColumns] = useState(
        new Set(["Number", "Name", "Phone Number", "score"])
    );

    function addParticipant(role, input) {
        const participantData = {
            eventId: eventId,
            participantRole: role,
            participantName: input.name,
            participantPhoneNumber: input.phoneNumber,
            participantEmail: input.email,
            participantAdmin: input.admin
        };

        if (eventDetails[`${role}s`].length < eventDetails.eventData[0].registrarLimit) {
            if (validateParticipantData(participantData)) {
                Ajax.request({
                    url:"/JudgeAutos/addParticipant",
                    jsonData: participantData,
                    success:function(reply) {
                        swal.fire({
                            title:`Added ${role}`,
                            text:`${participantData.participantName} has been added!`,
                            icon:"success",
                            timer: 1500,
                            didClose: () => {
                                window.location.reload();
                            }
                        })
                    }
                });
            }
        } else {
            swal.fire({
                title: "Oops!",
                text: `You have reached your ${role} limit!`,
                icon: "error"
            });
        }
    }

    const deleteParticipant = (participantData) => {
        Ajax.request({
            url:"/JudgeAutos/deleteParticipant",
            jsonData: participantData,
            success:function(reply) {
                swal.fire({
                    title:`Deleted ${participantData.participantRole}!`,
                    text: reply.data,
                    icon:"success",
                    timer: 3000,
                    didClose: () => {
                        window.location.reload();
                    }
                })
            }
        });
    }

    const handleSelectedRegistrationColumns = (keys) => {
        setSelectedRegistrationColumns(new Set(keys));
    };

    const updateParticipant = (participantRole, input) => {
        const roleId = participantRole === "judge" ? modalData.participantData.judgeId : modalData.participantData.registrarId;
        
        const participantData = {
            participantRole: participantRole,
            participantId: roleId,
            participantName: input.name,
            participantPhoneNumber: input.phoneNumber.replace(/[^0-9]/g, ''),
            participantEmail: input.email,
            participantAdmin: input.admin
        }

        if (validateParticipantData(participantData)) {
            Ajax.request({
                url:"/JudgeAutos/updateParticipant",
                jsonData: participantData,
                success:function(reply) {
                    swal.fire({
                        title:`${participantRole.charAt(0).toUpperCase() + participantRole.slice(1)} updated!`,
                        text: reply.data,
                        icon:"success",
                        timer: 3000,
                        didClose: () => {
                            window.location.reload();
                        }
                    })
                }
            });
        }
    }

    const openModal = (participantRole, participantData) => {
        if (!["completed"].includes(eventStatus)) {
            setModalOpen(true);
            setModalData({
                participantRole: participantRole,
                participantData: participantData
            });
        }
    }

    const viewResults = () => {
        navigate('/Results', {
            state: {
                eventId,
                eventName,
                eventStatus
            } 
        });
    };

    function sendInvite(contactName, contactInformation, qrCode, userRole, contactMethod) {
        const contactForm = {
            name: contactName,
            contactInformation: contactInformation,
            qrCode: qrCode,
            eventName: eventName,
            userRole: userRole,
            contactMethod: contactMethod
        }
        
        Ajax.request({
            url:"/JudgeAutos/sendInvite",
            jsonData: contactForm,
            success:function() {
                swal.fire({
                    title: "Sent!",
                    text: `An invite has been sent to ${contactForm.name}`,
                    icon: "success"
                });
            }
        });
    }

    function updateEvent() {
        const currentDate = getCurrentDate(); 
        const updateForm = {
            eventId: eventDetails.eventData.find(id => id.eventId).eventId,
            eventName:document.getElementById("eventName").value,
            eventLocation:document.getElementById("eventLocation").value,
            eventDate:document.getElementById("eventDate").value,
            rainoutDate:document.getElementById("rainoutDate").value,
            registrationForm: registrationForm,
            registrationFormId: eventDetails.registrationForms.find(form => form.registrationFormId == registrationForm).registrationFormId,
            judgeForm: judgeForm,
            judgeFormId: eventDetails.judgeForms.find(form => form.judgeFormId == judgeForm).judgeFormId,
            classListId: eventDetails?.classLists?.find(id => id.classListName === classList)?.classListId ?? null
        };
        
        if (updateForm.eventDate === currentDate) {
            updateForm.status = "active"
        } else if (updateForm.eventDate > currentDate) {
            updateForm.status = "upcoming"
        } else {
            swal.fire({
                title: "Invalid event date",
                text: "The event date cannot be in the past!",
                icon: "error",
                timer: 1500
            })
            return;
        }

        if (updateForm.rainoutDate === "") {
            updateForm.rainoutDate = null;
        }

        Ajax.request({
            url:"/JudgeAutos/updateEvent",
            jsonData:updateForm,
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: "Event Updated",
                    icon: "success",
                    timer: 1500,
                    didClose: () => {
                        window.location.reload();
                    }
                });
            }
        });
    }

    const validateParticipantData = (participantData) => {
        addParticipant = true;

        if (!participantData.participantName || !(participantData.participantPhoneNumber || participantData.participantEmail)) {
            swal.fire({
                title: "Missing fields!",
                html: `Please enter a <strong>name</strong> and a <strong>phone number or email</strong>.`,
                icon: "error",
                timer: 3000
            });
            addParticipant = false;
        }
            
        if (participantData.participantPhoneNumber && !validatePhoneNumber(participantData.participantPhoneNumber) && addParticipant) {
            swal.fire({
                title: "Invalid Phone Number",
                html: `Please enter a 10 digit phone number`,
                icon: "error",
                timer: 3000
            });
            addParticipant = false;
        }

        return addParticipant;
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
          "getKeyBelow",
          "getKeyAbove",
          "getKeyLeftOf",
          "getKeyRightOf",
          "getKeyPageBelow",
          "getKeyPageAbove",
          "getFirstKey",
          "getLastKey",
          // HAVE TO ignore this one
          // "getKeyForSearch"
        ].map((name) => [name, () => null]),
      );

    return(
        <div className="flex flex-col text-center mx-auto items-center w-fit">
            <h1 className="text-5xl">{eventName}</h1>
            <Spacer y={1} />
            <p>
                ({eventStatus})
            </p>
            {eventStatus === "completed" || eventStatus === "active" ? (
                <div>
                    <Spacer y={5} />
                    <div >
                        <Divider orientation="vertical"/>
                        <Divider orientation="vertical"/>
                    </div>
                    <Spacer y={4} />
                </div>
            ): (
                <Spacer y={9} />
            )}
            {eventDetails.eventData && (
                <Tabs
                    size={isMobile ? "sm" : "md"}
                    classNames={{
                        tabList: "gap-0 sm:gap-2"
                    }}
                >
                    {eventDetails.formData?.length > 0 && (
                        <Tab key="registrations" title="Registrations" aria-label="Registrations tab" >
                            {eventDetails.formSubmissions?.length > 0 ? (
                                <div>
                                    <div className="flex mx-auto justify-center -my-2 sm:my-auto items-center space-x-4">
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant="bordered"
                                                    color="default"
                                                >
                                                    Columns
                                                </Button>
                                            </DropdownTrigger>
                                                <DropdownMenu
                                                    disallowEmptySelection
                                                    aria-label="Registration Table Columns Dropdown"
                                                    selectionMode="multiple"
                                                    selectedKeys={Array.from(selectedRegistrationColumns)}
                                                    onSelectionChange={handleSelectedRegistrationColumns}
                                                    
                                                >
                                                    {Object.keys(eventDetails.formSubmissions[0]).map((key, index) => (
                                                        <DropdownItem key={`${key}`}>{key.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</DropdownItem>
                                                    ))}
                                                    <DropdownItem 
                                                        key="resetColumnSelectionsButton"
                                                        onClick={(e) => {
                                                            setSelectedRegistrationColumns(new Set(["Number", "Name", "Phone Number", "score"]))
                                                        }}
                                                    >
                                                        <div className="flex mx-auto justify-center">
                                                            <Button
                                                                variant="solid"
                                                                size={isMobile ? "sm" : "md"}
                                                                color="primary"
                                                                onClick={() =>
                                                                    setSelectedRegistrationColumns(new Set(["Number", "Name", "Phone Number", "score"]))
                                                                }
                                                            >
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                        </Dropdown>
                                        <Button 
                                            variant="bordered"
                                            color="default"
                                            onClick={viewResults}
                                        >
                                            Results
                                        </Button>
                                    </div>
                                    <Spacer y={4} />
                                    <Table
                                        isStriped
                                        aria-label="Event registrations"
                                        layout="fixed"
                                    >
                                        <TableHeader>
                                            {Object.keys(eventDetails.formSubmissions[0]).map((key, index) => (
                                                <TableColumn
                                                    key={key}
                                                    align="center"
                                                    className={
                                                        selectedRegistrationColumns.has(key)
                                                            ? "table-cell text-center"
                                                            : "hidden"
                                                    }
                                                >
                                                    {key.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                                </TableColumn>
                                            ))}
                                        </TableHeader>
                                        <TableBody>
                                            {eventDetails?.formSubmissions?.length > 0 && 
                                                eventDetails?.formSubmissions?.map((data, index) => (
                                                    <TableRow
                                                        key={index}
                                                        style={{cursor: "pointer"}}
                                                        // onClick={() => openModal("registration", eventDetails.formData, data, eventId)}
                                                    >
                                                        {Object.keys(data).map((key, innerIndex) => (
                                                            <TableCell
                                                                key={innerIndex}
                                                                className={
                                                                    selectedRegistrationColumns.has(key)
                                                                        ? "table-cell"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                {data[key]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : (
                                <div>
                                    <Spacer y={5} />
                                    <p>
                                        No cars have been registered just yet!
                                    </p>
                                    <Spacer y={5} />
                                    <Button
                                        variant="solid"
                                        color="primary"
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                    >
                                        Refresh
                                    </Button>
                                </div>
                            )}
                        </Tab>
                    )}
                    {/* {(eventDetails?.eventData?.find(limit => limit.classLimit)?.classLimit ?? 0) > 0  && ( */}
                    {classList !== "noClassList"  && (
                        <Tab key="classes" title="Classes" aria-label="Classes tab" >
                                <Table isStriped aria-label="Clases" keyboardDelegate={dummyKeyboardDelegate}>
                                    <TableHeader>
                                        <TableColumn>Class Number</TableColumn>
                                        <TableColumn>Class Name</TableColumn>
                                        <TableColumn>Registrations</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        {eventDetails.classes.map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{data.classNumber}</TableCell>
                                                <TableCell>{data.className}</TableCell>
                                                <TableCell>0</TableCell>
                                                {/* <TableCell>{eventDetails.registrations.filter(registration => registration.class === data.classNumber).length}</TableCell> */}
                                            </TableRow>
                                        ))};
                                    </TableBody>
                                </Table>
                        </Tab>
                    )}
                    <Tab key="registrars" title="Registrars" aria-label="Registrars tab">
                        <Table isStriped aria-label="Registrars" keyboardDelegate={dummyKeyboardDelegate}>
                            <TableHeader>
                                <TableColumn>Name</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Number</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Email</TableColumn>
                                <TableColumn>Cars Registered</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {eventDetails.registrars.map((data, index) => (
                                    <TableRow
                                        key={index}
                                        style={
                                            !["completed"].includes(eventStatus) ? { cursor: "pointer" } : { cursor: "default" }
                                        }
                                        onClick={() => openModal("Registrar", data)}
                                    >
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{data.phoneNumber}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{data.email}</TableCell>
                                        <TableCell>{data.carsRegistered}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                        <div className="p-5">
                            <Button
                                variant="solid"
                                color="primary"
                                size="sm"
                                isDisabled={
                                    ["completed"].includes(eventStatus) ||
                                    eventDetails.registrars.length >= eventDetails.eventData[0].registrarLimit
                                }
                                onClick={() => openModal("Registrar", modalInputValues)}
                            >
                                Add Registrar
                            </Button>
                        </div>
                    </Tab>
                    <Tab key="judges" title="Judges" aria-label="Judges tab">
                        <Table isStriped aria-label="Judges" keyboardDelegate={dummyKeyboardDelegate}>
                            <TableHeader>
                                <TableColumn>Name</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Number</TableColumn>
                                <TableColumn className="hidden sm:table-cell">Email</TableColumn>
                                <TableColumn>Cars Judged</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {eventDetails.judges.map((data, index) => (
                                    <TableRow
                                        key={index}
                                        style={
                                            !["completed"].includes(eventStatus) ? { cursor: "pointer" } : { cursor: "default"}
                                        }
                                        onClick={() => openModal("Judge", data)}
                                        
                                    >
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{data.phoneNumber}</TableCell>
                                        <TableCell className="hidden sm:table-cell">{data.email}</TableCell>
                                        <TableCell>{data.carsJudged}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                        <div className="p-5">
                            <Button
                                variant="solid"
                                color="primary"
                                size="sm"
                                isDisabled={
                                    ["completed"].includes(eventStatus) ||
                                    eventDetails.judges.length >= eventDetails.eventData[0].judgeLimit
                                }
                                onClick={() => openModal("Judge", modalInputValues)}
                            >
                                Add Judge
                            </Button>
                        </div>
                    </Tab>
                    <Tab key="eventDetails" title="Event Details" aria-label="Event Details Tab">
                        {eventDetails.eventData?.length > 0 && eventDetails.eventData.map((data, index) => (
                            <Form key={index}>                
                                <Input
                                    key={data.eventId}
                                    isReadOnly
                                    id="eventId"
                                    type="number"
                                    label="Event Id"
                                    variant="flat"
                                    defaultValue={data.eventId}
                                />
                                <Spacer y={1} />
                                <Popover placement="bottom" offset={10} showArrow>
                                    <PopoverTrigger>
                                        <Input
                                            isReadOnly
                                            key={data.status}
                                            id="eventStatus"
                                            type="text"
                                            label="Status"
                                            variant="flat"
                                            defaultValue={`${data.status.charAt(0).toUpperCase() + data.status.slice(1)} Event`}
                                            classNames={{
                                            input: "text-left",
                                            }}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="text-small font-bold">NOTICE</div>
                                        <div className="text-tiny">To change the status, update the Event Date</div>
                                    </div>
                                    </PopoverContent>
                                </Popover>
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={data.eventName}
                                    id="eventName"
                                    type="text"
                                    label="Event Name"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.eventName}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={`event-${data.eventDate}`}
                                    id="eventDate"
                                    type="date"
                                    label="Event Date"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={`${data.eventDate.split(" ")[0]}`}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={`rainout-${data.rainoutDate}`}
                                    id="rainoutDate"
                                    type="date"
                                    label="Rainout Date"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.rainoutDate ? data.rainoutDate.split(" ")[0] : ""}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={data.eventLocation}
                                    id="eventLocation"
                                    type="text"
                                    label="Event Location"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.eventLocation}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <div className="grid grid-cols-2 gap-2 place-content-center">
                                    <Dropdown>
                                        <DropdownTrigger>
                                            <Button
                                                variant={
                                                    eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                    eventDetails.eventData.find(value => value.forms).forms !== "custom" ? "solid" : "bordered"
                                                }
                                            >
                                                Registration Forms
                                            </Button>
                                        </DropdownTrigger>
                                            <DropdownMenu
                                                disabled={
                                                    eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                    eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                }
                                                disallowEmptySelection
                                                aria-label="Registration Form Dropdown Menu"
                                                selectionMode="single"
                                                selectedKeys={new Set([registrationForm])}
                                                onSelectionChange={
                                                    eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                    eventDetails.eventData.find(value => value.status).status !== "completed" && (
                                                        (selected) => {
                                                            const selectedKey = Array.from(selected)[0];
                                                            setRegistrationForm(selectedKey);
                                                            setEventDetailsUpdate(true)
                                                        }
                                                    )
                                                }
                                                
                                            >
                                                {eventDetails.registrationForms.map((data, index) => (
                                                    <DropdownItem key={data.registrationFormId}>{data.formName}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown>
                                        <DropdownTrigger>
                                            <Button
                                                variant={
                                                    eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                    eventDetails.eventData.find(value => value.forms).forms !== "custom" ? "solid" : "bordered"
                                                }
                                            >
                                                Judge Forms
                                            </Button>
                                        </DropdownTrigger>
                                            <DropdownMenu
                                                disabled={
                                                    eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                    eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                }
                                                disallowEmptySelection
                                                aria-label="Judge Form Dropdown Menu"
                                                selectionMode="single"
                                                selectedKeys={new Set([judgeForm])}
                                                onSelectionChange={
                                                    eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                    eventDetails.eventData.find(value => value.status).status !== "completed" && (
                                                        (selected) => {
                                                            const selectedKey = Array.from(selected)[0];
                                                            setJudgeForm(selectedKey);
                                                            setEventDetailsUpdate(true)
                                                        }
                                                    )
                                                }
                                            >
                                                {eventDetails.judgeForms.map((data, index) => (
                                                    <DropdownItem key={data.judgeFormId}>{data.formName}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown>
                                        <DropdownTrigger>
                                            <Button
                                                variant={
                                                    eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                    (eventDetails?.eventData?.find(limit => limit.classLimit)?.classLimit ?? 0) == 0 ? "solid" : "bordered"
                                                }
                                            >
                                                Class List
                                            </Button>
                                        </DropdownTrigger>
                                            <DropdownMenu
                                                disabled={
                                                    eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                }
                                                disallowEmptySelection
                                                aria-label="Class List Dropdown Menu"
                                                selectionMode="single"
                                                selectedKeys={new Set([classList])}
                                                onSelectionChange={
                                                    (eventDetails?.eventData?.find(value => value.classLimit)?.classLimit ?? 0) > 0 && (
                                                        (selected) => {
                                                            const selectedKey = Array.from(selected)[0];
                                                            setClassList(selectedKey);
                                                            setEventDetailsUpdate(true)
                                                        }
                                                    )
                                                }
                                            >
                                                <DropdownItem key="noClassList">None</DropdownItem>
                                                {eventDetails.classLists.map((data, index) => (
                                                    <DropdownItem key={data.classListName}>{data.classListName}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="eventQrCode"
                                    type="text"
                                    label="Link"
                                    variant="flat"
                                    defaultValue={`judgeautos.com/JudgeAutos/Events?code=${data.qrCode}`}
                                />
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="salesId"
                                    type="number"
                                    label="Sales Id"
                                    variant="flat"
                                    defaultValue={data.salesId}
                                />
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="productName"
                                    type="text"
                                    label="Product"
                                    variant="flat"
                                    defaultValue={data.productName}
                                />
                                <Spacer y={2} />
                                {
                                    eventDetails.eventData.find(value => value.status).status !== "completed" && 
                                    eventDetailsUpdate && (
                                    <Button
                                        onClick={updateEvent}
                                        color="primary"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Form>
                        ))}
                    </Tab>
                </Tabs>
            )}
                <Modal 
                    isOpen={modalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setModalInput(false);
                        setModalInputValues((prevValues) => ({
                            ...prevValues,
                            name: '',
                            phoneNumber: '',
                            email: '',
                            admin: ''
                        }))                            
                    }}
                    placement="center"
                >
                    <ModalContent>
                        <ModalHeader>
                            {modalData.participantData && (
                                Object.values(modalData.participantData).every(value => value === '') ? (
                                    <>Add {modalData.participantRole}</>
                                ) : (
                                    <>Edit {modalData.participantRole}</>
                                )
                            )}
                        </ModalHeader>
                            <ModalBody>
                                {modalData.participantData &&
                                    Object.entries(modalData.participantData).filter(
                                        ([key]) => key !== "registrarId" && key !== "judgeId" && key !== "eventId" && key !== "qrCode"
                                    ).map(([key, value]) => (
                                        key === "admin" ? (
                                            <div className="bg-slate-100 rounded-lg">
                                                <p className="text-xs pl-3 py-2">
                                                    Administrator
                                                </p>
                                                <Spacer y={1} />
                                                <div className="flex mx-auto w-full justify-center">
                                                    {[1, 0].map((bool) => (
                                                        <Button
                                                            key={key}
                                                            value={value}
                                                            variant={modalInputValues[key] === bool ? "solid" : "ghost"}
                                                            color="primary"
                                                            className={`${bool === 1 ? "rounded-r-none w-1/2" : "rounded-l-none w-1/2"}`}
                                                            onClick={() => {
                                                                setModalInput(true);
                                                                setModalInputValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    [key]: bool
                                                                }));
                                                            }}
                                                        >
                                                            {bool === 1 ? "Yes" : "No"}
                                                        </Button>
                                                    ))}
                                                </div>                                                
                                            </div>
                                        ) : (
                                            <Input 
                                                key={key}
                                                id={key}
                                                type={key === "email" ? "email" : "text"}
                                                isInvalid={key === "email" && !validEmail}
                                                label={key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase())}
                                                variant="flat"
                                                value={modalInputValues[key]}
                                                onChange={(event) => {
                                                    setModalInput(true)
                                                    setModalInputValues((prevValues) => ({
                                                        ...prevValues,
                                                        [key]: event.target.value
                                                    }))
                                                }}
                                                endContent={
                                                    (() => {
                                                        if (key === "name" && value !== '') {
                                                            return (
                                                                <Button
                                                                    variant="light"
                                                                    onClick={() => {
                                                                        swal.fire({
                                                                            title: "Confirm Deletion",
                                                                            text: `Delete ${modalData.participantData.name}?`,
                                                                            icon: "warning",
                                                                            showConfirmButton: true,
                                                                            confirmButtonText: "Delete",
                                                                            showCancelButton: true,
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                deleteParticipant(
                                                                                    {
                                                                                        participantId: modalData.participantData[modalData.participantRole === "Registrar" ? "registrarId" : "judgeId"],
                                                                                        participantRole: modalData.participantRole,
                                                                                        participantName: modalData.participantData.name
                                                                                    }
                                                                                );
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    <Trash_8_Red />
                                                                </Button>
                                                            );
                                                        } else if (["email", "phoneNumber"].includes(key) && value !== '') {
                                                            return (
                                                                <Button
                                                                    variant="light"
                                                                    isDisabled={
                                                                        (key === "email" && !validEmail) ||
                                                                        (key === "phoneNumber" && !validatePhoneNumber(modalInputValues[key]))
                                                                    }
                                                                    onClick={() => {
                                                                        swal.fire({
                                                                            title: "Confirm Invitation",
                                                                            text: `Send invite to ${modalInputValues[key]}?`,
                                                                            icon: "warning",
                                                                            showConfirmButton: true,
                                                                            confirmButtonText: "Send",
                                                                            showCancelButton: true,
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                sendInvite(
                                                                                    modalData.participantData.name,
                                                                                    modalInputValues[key],
                                                                                    modalData.participantData.qrCode,
                                                                                    modalData.participantRole,
                                                                                    key
                                                                                )
                                                                                
                                                                                setTimeout(() => {
                                                                                    if (value !== modalInputValues[key]) {
                                                                                        updateParticipant(modalData.participantRole.toLowerCase(), modalInputValues);
                                                                                    }
                                                                                }, 1500);
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    <ShareIcon_6_Blue />
                                                                </Button>
                                                            );
                                                        }
                                                    })()
                                                }
                                            />
                                        )
                                    )
                                )}
                                <Button
                                    variant="solid"
                                    className="w-48 mx-auto"
                                    isDisabled={!modalInput || !validEmail}
                                    color="primary"
                                    size="lg"
                                    onClick={() => {
                                        if (modalInput) {
                                            const role = modalData.participantRole.toLowerCase();

                                            if (modalData.participantData.judgeId || modalData.participantData.registrarId) {
                                                updateParticipant(role, modalInputValues);
                                            } else {
                                                addParticipant(role, modalInputValues);
                                            }
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </ModalBody>
                    </ModalContent>
                </Modal>
        </div>
    );
}