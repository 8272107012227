import React, {
    useEffect
} from "react";
import swal from 'sweetalert2';

export default function Error404() {
    useEffect(() => {
        swal.fire({
            title:"Page Not Found",
            text: "Sorry, that page does not exist",
            icon: "error",
            timer: 3000,
            didClose: () => {
                document.location.href = "/";
            }
        })
    }, []);

    return(
        <div>
            
        </div>
    );
}