import React, {
    useEffect
} from "react";
import {
    Button,
    Divider,
    Link,
    Spacer
} from "@nextui-org/react";
import swal from "sweetalert2";
import Ajax from "../inc/js/Ajax";
import {
    Circle_Check_6_Blue
} from "../inc/images/svgs"

export default function Home() {
    useEffect(() => {
        Ajax.request({
            url:'/JudgeAutos/checkEnvironment',
            success:function(reply) {
                if (reply.data){
                    swal.fire({
                        title: `Development Site`,
                        html: `
                            You have reached the development site! <br />
                            To use this application, please visit <a href="https://judgeautos.com" style="color:blue; text-decoration:underline;">JudgeAutos.com</a>!
                        `,
                        icon: "info",
                        showConfirmButton: false
                    })
                }
            }
        })
    }, []);

    const buttonList = "flex items-center justify-start text-md sm:text-right text-left w-56";

    return (
        <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 min-h-72">
                <h1 className="text-5xl text-center sm:text-right">
                    Simplifying your car show!
                </h1>
                <div className="block sm:hidden">
                    <Spacer y={2} />
                </div>
                <p className="text-2xl text-center max-w-md">
                    With our easy-to-use web app, simplify judging, registration, classes, and awards at your next event!
                </p>
            </div>
            <Spacer y={5} />
            <Divider className="my-10" />
            <div className="flex flex-col items-center justify-center text-center">
                <h2 className="text-3xl font-bold mb-4">Sign Up Now!</h2>
                <p className="text-xl max-w-md">
                    Experience an easy-to-use solution for managing your car show. Sign up today and see how our platform can simplify your experience!
                </p>
                <Spacer y={6} />
                <Button
                    size="lg"
                    color="primary"
                    onClick={() => {
                        document.location.href="/CreateAccount"
                    }}
                >
                    Create Account!
                </Button>
            </div>
            <Divider className="my-10" />
            <div className="flex sm:flex-row items-center justify-center sm:h-auto">
                <div className="text-center max-w-md sm:text-left sm:order-3">
                    <h2 className="text-3xl font-bold mb-4">Features</h2>
                    <p className="text-xl">
                        Discover the features that make JudgeAutos stand out!
                    </p>
                </div>
                <div className="block sm:hidden">
                    <Spacer y={5} />
                </div>
                <Divider orientation="vertical" className="sm:min-h-48 sm:ml-1 sm:mr-4 sm:visible sm:order-2" />
                <div className="flex flex-col items-start sm:items-center gap-1 sm:order-1 min-w-56 sm:min-w-96">
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_Check_6_Blue />}
                        variant="light"
                        onClick={() => {
                            window.location.href = "/Pricing";
                        }}
                    >
                        Various Packages 
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_Check_6_Blue />}
                        variant="light"
                    >
                        Class Lists
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_Check_6_Blue />}
                        variant="light"
                    >
                        Custom Forms
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_Check_6_Blue />}
                        variant="light"
                    >
                        Easy To Use
                    </Button>
                </div>
            </div>
            <Divider className="my-10" />
            <div className="flex flex-col items-center justify-center text-center">
                <h2 className="text-3xl font-bold mb-4">Getting Started</h2>
                <p className="text-xl max-w-lg">
                    Ready to see how it all works? Take a look at how to get started with JudgeAutos.
                </p>
                <Spacer y={6} />
                <Button
                    size="lg"
                    color="primary"
                    onClick={() => {
                        window.location.href = "/GettingStarted";
                    }}
                >
                    Take a look!
                </Button>
            </div>
        </div>
      );
}