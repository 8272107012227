import React, {useEffect} from "react";
import {
    Button,
    Divider,
    Link,
    Spacer
} from "@nextui-org/react";
import {
    Circle_1_Blue,
    Circle_2_Blue,
    Circle_3_Blue,
    Circle_4_Blue,
    Circle_Check_6_Blue
} from "../inc/images/svgs"

export default function GettingStarted() {
    const buttonList = "flex items-center justify-start text-md sm:text-right text-left w-56";

    return(
        <div className="flex-col text-center">
            <div className="flex sm:flex-row items-center justify-center sm:h-auto">
                <div className="text-center mr-2 sm:mr-0 sm:max-w-md order-1">
                    <h1 className="text-3xl font-bold mb-4 sm:hidden">Getting Started in a Few Simple Steps!</h1>
                    <h1 className="hidden text-3xl font-bold mb-4 sm:block">Getting Started in Just a Few Simple Steps!</h1>
                </div>
                <Divider orientation="vertical" className="min-h-48 order-2" />
                <div className="flex flex-col items-start sm:items-center gap-1 order-3 min-w-56 sm:min-w-96">
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_1_Blue />}
                        variant="light"
                        onClick={() => {
                            window.location.href = "/CreateAccount";
                        }}
                    >
                        Create Account
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_2_Blue />}
                        variant="light"
                        onClick={() => {
                            window.location.href = "/Pricing";
                        }}
                    >
                        Choose Package
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_3_Blue />}
                        variant="light"
                        onClick={() => {
                            window.location.href = "/Events";
                        }}
                    >
                        Create Event
                    </Button>
                    <Button
                        className={buttonList}
                        size="lg"
                        startContent={<Circle_4_Blue />}
                        variant="light"
                        onClick={() => {
                            window.location.href = "/Docs";
                        }}
                    >
                        Host Event
                    </Button>
                </div>
            </div>
            <Divider className="my-10" />
            <Spacer y={5} />
            <div className="w-11/12 sm:w-1/2 mx-auto text-lg">
                <h2 className="text-3xl my-5">Create Your Account</h2>
                <div className="sm:order-3">
                    <p>
                        Set up your account on the <Link href="/CreateAccount" underline="always" size="lg">Create Account</Link> page.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">Choose a Package</h2>
                <div>
                    <p>
                        <Link href="/LogIn" underline="always" size="lg">Log In</Link>, and choose a package on the <Link href="/Pricing" underline="always" size="lg">Pricing</Link> page that fits your needs.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">Create Your Event</h2>
                <div>
                    <p>
                        Create the event on your <Link href="/Events" underline="always" size="lg">Events</Link> page.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">Host Your Event</h2>
                <div>
                    <p>
                        You're all set! Manage and monitor your event through the <Link href="/Events" underline="always" size="lg">Events</Link> page.
                    </p>
                </div>
            </div>
            
            <Divider className="mt-10 mb-20" />
            <div>
                <h3 className="text-4xl sm:text-5xl my-5">Need more information?</h3>
                <p className="text-lg">
                    {/* View our <Link href="/Docs" underline="always" size="lg">Documentation</Link> on everything JudgeAutos or take a look at our <Link href="/FAQ" underline="always" size="lg">FAQ</Link>! */}
                    Take a look at our <Link href="/FAQ" underline="always" size="lg">Frequently Asked Questions</Link>, or <Link href="/ContactUs" underline="always" size="lg">Contact Us</Link>!
                </p>
            </div>
        </div>
    );
}