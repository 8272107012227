import React, {
    useEffect,
    useState
} from "react";
import { 
    Form
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";
import {
    Button,
    Input,
    Link,
    Spacer,
    Tab,
    Tabs
} from "@nextui-org/react";

export default function Registration() {
    const [registrationInfo, setRegistrationInfo] = useState([]);    
    const [formData, setFormData] = useState({});

    useEffect(() => {
        Ajax.request({
            url:'/JudgeAutos/loadRegistration',
            success:function(reply) {
                if(reply.data) {
                    setRegistrationInfo(reply.data);                    
                    setFormData(({
                        Number: "number"
                    }));
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: reply,
                    text: "Failed to load registration",
                    icon: {reply},
                    didClose: () => {
                        document.location.href = "/"
                    }
                })
            }
        });
    }, []);

    const handleInputChange = (attribute, value) => {
        if (/^[\d-]+$/.test(value)) {
            value = value.replace(/-/g, '');
        }
        
        setFormData(prevState => {
            let newForm = {
                ...prevState
            };

            newForm[attribute] = value;
            return newForm;
        });
    };

    const addAttendee = () => {
        if (!formData.Number) {
            formData.Number = "number";
        }
        if (Object.keys(formData).length === registrationInfo.registrationFormData.length) {
            if (formData["Phone Number"].replace(/-/g, '').length === 10) {
                Ajax.request({
                    url:'/JudgeAutos/addAttendee',
                    jsonData: {
                        eventId: registrationInfo.registrarInfo.eventId,
                        registrationFormId: registrationInfo.registrarInfo.registrationFormId,
                        formData: [formData]
                    },
                    success:function(reply) {
                        if(reply.data) {
                            console.log(reply.data);
                            swal.fire({
                                title: "Success",
                                text: `Successfully registered as ${reply.data}!`,
                                icon: "success",
                                timer: 1000,
                                showConfirmButton: false
                            }).then(() => (
                                window.location.reload()
                            ))
                        }
                    }
                });
            } else {
                swal.fire({
                    title: "Invalid Phone Number",
                    text: "Please enter a 10 digit phone number!",
                    icon: "error",
                    timer: 1500
                })
                return;
            }
        } else {
            swal.fire({
                title: "Missing value",
                text: "Please fill in all fields!",
                icon: "error",
                timer: 1500
            })
            return;
        }
    };

    return(
        <div className="flex-col text-center">
            <Link href="/Registration" color="foreground">
                <h1 className="text-5xl">Registration</h1>
            </Link >
            <Spacer y={4} />
            {registrationInfo.registrarInfo ? (
                <p>Welcome {registrationInfo.registrarInfo.name}!</p>
            ):(
                <p>Welcome!</p>
            )}
            <Spacer y={6} />
            {registrationInfo.registrarInfo && (
                <Tabs>
                    <Tab key="register" title="Register" aria-label="Register tab" >
                        <Form key="register">
                            {registrationInfo.registrationFormData.filter(data =>
                                (data.attribute !== "Class" || registrationInfo.registrarInfo.classLimit !== 0)
                            ).map((data, index) =>(
                                <React.Fragment key={index}>
                                    <Input
                                        isRequired={data.attribute === "Number" ? false : true}
                                        label={data.attribute}
                                        variant="bordered"
                                        type={
                                            /email/i.test(data.attribute) ? 'email' :
                                            /number|year|class|phone/i.test(data.attribute) ? 'number' :
                                            /date/i.test(data.attribute) ? 'date' :
                                            'text'
                                        }  
                                        inputMode={
                                            /number|year|class|phone/i.test(data.attribute) ? 'numeric' :
                                            undefined
                                        }
                                        onChange={(e) => handleInputChange(data.attribute, e.target.value)}
                                    />
                                    {index !== registrationInfo.registrationFormData.length - 1 && <Spacer y={1} />}
                                </React.Fragment>
                            ))}
                            <Spacer y={2} />
                            <Button
                                onClick={addAttendee}
                                color="primary"
                            >
                                Add
                            </Button>
                        </Form>
                    </Tab>
                    {registrationInfo.registrarInfo.admin === 1 && (
                        <Tab key="registrations" title="Registrations" aria-label="Registrations tab" >
                        
                        </Tab>
                    )}
                </Tabs>
            )}
        </div>
    );
}