import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { 
    Form
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";
import {
    Button,
    Divider,
    Input,
    Link,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";

export default function Judging() {
    useEffect(() => {     
        Ajax.request({
            url: '/JudgeAutos/loadJudge',
            jsonData: judgeInfo,
            success:function(reply) {
                if(reply.data) {
                    setJudgeInfo(reply.data);
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: "Oops!",
                    text: reply,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/"
                    }
                })
            }
        });
    }, [])

    const number = useRef({});
    const [formData, setFormData] = useState([]);
    const [inputs, setInputs] = useState({});
    const [judgeInfo, setJudgeInfo] = useState([]);
    const [judgingInfo, setJudgingInfo] = useState([]);
    const [scoreSum, setScoreSum] = useState(0);

    const loadJudging = () => {
        Ajax.request({
            url: '/JudgeAutos/loadJudging',
            jsonData: judgeInfo.judgeInfo,
            success:function(reply) {
                if(reply.data) {
                    if (reply.data.registrationSubmissions.length === 0) {
                        swal.fire({
                            title:`${judgeInfo.judgeInfo.number} is invalid.`,
                            html:"Double check your entry or the registration.",
                            icon:"error"
                        })
                    } else {
                        setJudgingInfo(reply.data);
                    }
                }
            }
        });
    }

    const handleInputChange = (attribute, value) => {
        const newInputs = { ...inputs, [attribute]: Number(value) };
        setInputs(newInputs);
        
        const newScoreSum = Object.values(newInputs).reduce((sum, val) => sum + val, 0);
        setScoreSum(newScoreSum);
        
        setFormData(prevState => {
            let newForm = {
                ...prevState
            };

            newForm[attribute] = value;
            newForm["Total Score"] = newScoreSum;
            return newForm;
        });
    };

    const handleNumberChange = () => {
        setJudgeInfo((prevInfo) => ({
            ...prevInfo,
            judgeInfo: {
                ...prevInfo.judgeInfo,
                number: number.current.value
            }
        }));
    }

    const submitScore = () => {
        if (Object.keys(formData).length === judgingInfo.judgeFormData.length) {
            for (const value of judgingInfo.judgeFormData) {
                if (formData[value.attribute] > value.maxScore) {
                    swal.fire({
                        title: "Max score exceeded!",
                        text: `${formData[value.attribute]} is greater than ${value.attribute}'s max score ${value.maxScore}`,
                        icon: "error"
                    })
                    return;
                }
            }
        } else {
            swal.fire({
                title: "Missing value!",
                text: "Please fill in all fields!",
                icon: "error",
                timer: 1500
            })
            return;
        }

        Ajax.request({
            url:'/JudgeAutos/submitScore',
            jsonData: {
                eventId: judgeInfo.judgeInfo.eventId,
                formData: [formData],
                judgeFormId: judgingInfo.judgeFormData[0].judgeFormId,
                number: judgingInfo.registrationSubmissions.find(attribute => attribute.attribute.toLowerCase() === "number").value,
                registrationId: judgingInfo.registrationSubmissions[0].registrationId
            },
            success:function(reply) {
                if(reply.data) {
                    swal.fire({
                        title: "Score submitted!",
                        text: `${reply.data} was judged successfully!`,
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false
                    }).then(() => (
                        window.location.reload()
                    ))
                }
            }
        });
    };

    return(
        <div className="flex-col text-center">
            <Link href="/Judging" color="foreground">
                <h1 className="text-5xl">Judging</h1>
            </Link>
            <Spacer y={10} />
            {judgeInfo.judgeInfo && !judgingInfo.judgeFormData && (
                    <p>
                        Welcome {judgeInfo.judgeInfo.name}! <br />
                        Enter a number to get started.
                    </p>
            )}

            {judgingInfo.judgeFormData && (
                <p>
                    Judge carefully!
                </p>               
            )}
            
            <Spacer y={5} />
            {judgeInfo.judgeInfo && !judgingInfo.judgeFormData && (
                <div>
                    <Form>
                        <Input 
                            isRequired
                            ref={number}
                            label="Number"
                            variant="bordered"
                            type="number"
                            inputMode="tel"
                            onChange={handleNumberChange}
                        />
                        <Spacer y={2} />
                        <Button
                            onClick={loadJudging}
                            color="primary"
                        >
                            Enter
                        </Button>
                    </Form>
                    <Divider className="my-10" />
                    <h2 className="text-2xl">Unjudged Registrations</h2>
                    <Spacer y={5} />
                    {Object.values(judgeInfo.unjudgedInfo).some(obj => Object.keys(obj).length > 0) ? (
                        <Table isStriped aria-label="Unjudged Table">
                            <TableHeader>
                                {Object.keys(Object.values(judgeInfo.unjudgedInfo)[0])
                                    .filter(
                                        key =>
                                            key === "Number" ||
                                            key.toLowerCase().includes("year") ||
                                            key.toLowerCase().includes("model") ||
                                            key.toLowerCase().includes("color")
                                    )
                                    .map((filteredKey, index) => (
                                        <TableColumn key={index}>{filteredKey}</TableColumn>
                                    ))}
                            </TableHeader>
                            <TableBody>
                            {Object.values(judgeInfo.unjudgedInfo).map((item, index) => (
                                <TableRow>
                                        {Object.keys(item)
                                        .filter(
                                            key =>
                                                key === "Number" ||
                                                key.toLowerCase().includes("model") ||
                                                key.toLowerCase().includes("year") ||
                                                key.toLowerCase().includes("color")
                                        )
                                        .map((filteredKey, index) => (
                                            <TableCell key={index}>{item[filteredKey]}</TableCell>
                                        ))}
                                </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <p>
                            Nothing to judge!
                        </p>
                    )}
                </div>
            )}
            {judgingInfo.judgeFormData && (
                <div>
                    <div className="grid grid-cols-2 gap-2 place-content-center">
                        {judgingInfo.registrationSubmissions.map((data,index) => (
                            !data.attribute.toLowerCase().includes("name") && !data.attribute.toLowerCase().includes("phone") && (
                                <Input
                                    isReadOnly
                                    key={data.attribute}
                                    label={data.attribute}
                                    value={data.value}
                                    variant="flat"
                                />
                            )
                        ))}
                    </div>
                    <Divider className="my-10" />
                    <Form key="judge">
                        {judgingInfo.judgeFormData.map((data, index) => (
                            <React.Fragment key={index}>
                                {data.attribute !== "Total Score" ? (
                                    <Input
                                        isRequired
                                        key={data.attribute}
                                        label={data.attribute}
                                        variant="bordered"
                                        type="number"
                                        inputMode="tel"
                                        placeholder={`${data.maxScore} MAX`}
                                        onChange={(e) => handleInputChange(data.attribute, e.target.value)}
                                    />
                                ) : (
                                    <Input
                                        isReadOnly
                                        value={scoreSum}
                                        key={data.attribute}
                                        label={data.attribute}
                                        variant="flat"
                                        endContent={`/${data.maxScore}`}
                                    />                                        
                                )}
                                {index !== judgingInfo.judgeFormData.length - 1 && <Spacer y={1} />}
                            </React.Fragment>
                        ))}
                        <Spacer y={4} />
                        <Button
                            onClick={submitScore}
                            color="primary"
                        >
                            Add
                        </Button>
                    </Form>
                </div>
            )}
        </div>
    );
}