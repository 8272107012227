import React, {
    useEffect,
    useState
} from "react";
import {
    useLocation
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";
import swal from "sweetalert2";


export default function Results({clientInformation}) {
    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        } else {            
            Ajax.request({
                url:'/JudgeAutos/readResults',
                jsonData: requestForm,
                success:function(reply) {
                    setResultData(reply.data);
                    setSortedAllResults(
                        Object.values(reply.data.results).sort((a, b) => b.score - a.score)
                    );

                    setTopScorers(() => {
                        const results = reply.data.results;
                        const maxScore = Math.max(...Object.values(results).map(car => car.score));
                        const topScores = Object.values(results).filter(car => car.score === maxScore);
                        return topScores;
                    });

                    if (reply.data.classlistdata.length > 0) {
                        const missingClassesSet = new Set();
                        const groupedClassResults = Object.values(reply.data.results).reduce((acc, item, index) => {
                            if (item.Class) {
                                if (!acc[item.Class]) {
                                    acc[item.Class] = [];
                                }
                                acc[item.Class].push(item);
                            } else {
                                missingClassesSet.add(`Registration ${item.Number} \n`);                              
                            }
                            return acc;
                        }, {});

                        setMissingClasses(Array.from(missingClassesSet));

                        setSortedClassResults(
                            Object.keys(groupedClassResults).map(classNumber => {
                                return {
                                    class: classNumber,
                                    className: reply.data.classlistdata.find(name => name.classNumber == classNumber).className,
                                    winners: groupedClassResults[classNumber].sort((a, b) => b.score - a.score).slice(0, 3)
                                }
                            })
                        )
                    }
                },
                failure:function(reply) {
                    swal.fire({
                        title: "Failed to load result data",
                        text: "Please try again!",
                        icon: "error",
                        didClose: () => {
                            document.location.href = "/Events"
                        }
                    })
                }
            });
        }
    }, [clientInformation]);

    const [missingClasses, setMissingClasses] = useState([]);

    useEffect(() => {
        if (missingClasses !== null && missingClasses.length > 0) {
            swal.fire({
                title: "Registrations missing classes",
                html: missingClasses.join("<br />"),
                icon: "error",
            });
        }
    }, [missingClasses]);
    
    const location = useLocation();    
    const [resultData, setResultData] = useState([]);
    const [topScorers, setTopScorers] = useState([]);
    const [sortedAllResults, setSortedAllResults] = useState([]);
    const [sortedClassResults, setSortedClassResults] = useState([]);
    const [requestForm] = useState({
        eventId: location.state?.eventId || localStorage.getItem('eventId'),
        eventName: location.state?.eventName || localStorage.getItem('eventName'),
        eventStatus: location.state?.eventStatus || localStorage.getItem('eventStatus')
    });
    
    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">{requestForm.eventName} Results</h1>
            <Spacer y={10} />
            {resultData.formData && (
                <Tabs>
                    <Tab key="allResults" title="All" aria-label="All results tab" >
                        {Object.keys(resultData.results).length > 0 ? (
                            <Table isStriped aria-label="All results table">
                                <TableHeader>
                                    {resultData.formData.filter(
                                        data => data.attribute !== "Class" ||
                                        (data.attribute === "Class" && resultData.classlistdata.length > 0)).map((data) => (
                                            <TableColumn key={data.attribute}>{data.attribute}</TableColumn>
                                    ))}
                                    <TableColumn>Score</TableColumn>
                                </TableHeader>
                                <TableBody>
                                    {sortedAllResults.map((data, index) => (
                                        <TableRow key={index}>
                                            {resultData.formData.filter(
                                                data => data.attribute !== "Class" ||
                                                (data.attribute === "Class" && resultData.classlistdata.length > 0)
                                            ).map((header) => (
                                                <TableCell key={header.attribute}>{data[header.attribute]}</TableCell>
                                            ))}
                                            <TableCell>{data.score}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <div>
                                <Spacer y={2} />
                                <p>
                                    No cars have been judged!
                                </p>
                            </div>
                        )}
                    </Tab>
                    {resultData.classlistdata.length > 0 && (
                        <Tab key="byClass" title="By Class" aria-label="Results by class tab" >
                            {sortedClassResults.length > 0 ? (
                                sortedClassResults.map((data, index) => (
                                    <div key={index}>
                                        <h2 className="text-3xl">{data.class}: {data.className}</h2>
                                        <Spacer y={2} />
                                        <Table aria-label={`${data.className} Table`}>
                                            <TableHeader>
                                                {Object.keys(data.winners[0]).filter(key => key !== "Class" && key !== "Class Name" && key !== "Phone Number").map((key, keyIndex) => (
                                                    <TableColumn key={keyIndex}>{key}</TableColumn>
                                                ))}
                                            </TableHeader>
                                            <TableBody>
                                                {data.winners.map((winner, winnerIndex) => (
                                                    <TableRow key={winnerIndex}>
                                                        {Object.keys(winner).filter(key => key !== "Class" && key !== "Class Name" && key !== "Phone Number").map((key, valueIndex) => (
                                                            <TableCell key={valueIndex}>{winner[key]}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Spacer y={5} />
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <Spacer y={2} />
                                    <p>
                                        No cars have been judged!
                                    </p>
                                </div>
                            )}
                        </Tab>
                    )}
                    <Tab key="topScorers" title="Top Scorers" aria-label="Top Scorers Tab">
                        {Object.keys(resultData.results).length > 0 ? (
                            <Table aria-label="Top Scorers Table">
                                <TableHeader>
                                    {Object.keys(topScorers[0]).filter(key => key !== "Class" && key !== "Class Name" && key !== "Phone Number").map((key, keyIndex) => (
                                        <TableColumn key={keyIndex}>{key}</TableColumn>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {topScorers.map((winner, winnerIndex) => (
                                        <TableRow key={winnerIndex}>
                                            {Object.keys(winner).filter(key => key !== "Class" && key !== "Class Name" && key !== "Phone Number").map((key, valueIndex) => (
                                                <TableCell key={valueIndex}>{winner[key]}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <div>
                                <Spacer y={2} />
                                <p>
                                    No cars have been judged!
                                </p>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            )}
        </div>
    );
}