import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { 
    useLocation,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    PlusCircle_6_Blue
} from "../inc/images/svgs";
import {
    Input,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function Forms({clientInformation}) {
    useEffect(() => {
        if (clientInformation.userInformation.loggedIn === true) {
            Ajax.request({
                url:"/JudgeAutos/readForms",
                success:function(reply) {
                    setForm(reply.data);
                }
            });
        } else if (clientInformation.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [clientInformation]);
    
    const navigate = useNavigate();
    const location = useLocation();
    const newClassListName = useRef();
    const newClassListDescription = useRef();

    const [form, setForm] = useState([]);

    const createClassList = () => {
        const newList = {
            classListName: newClassListName.current.value,
            classListDescription: newClassListDescription.current.value
        }
        
        Ajax.request({
            url:"/JudgeAutos/createClassList",
            jsonData: newList,
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: `${reply.data} was created`,
                    icon: "success",
                    timer: 1500
                }).then(() =>{
                    window.location.reload();
                })
            }
        });
    }

    function createForm(type) {
        const newForm = {
            type:type,
            formName:document.getElementById(`${type}Name`).value,
            formDescription:document.getElementById(`${type}Description`).value
        };

        Ajax.request({
            url:"/JudgeAutos/createForm",
            jsonData: newForm,
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: `${reply.data} was created`,
                    icon: "success",
                    timer: 1500
                }).then(() =>{
                    window.location.reload();
                })
            }
        });
    }

    function loadForm(id) {
        console.log(id)
    }

    function loadList(listId, listType) {
        navigate("/List", {
            state: {
                listId,
                listType
            }
        });
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
            "getKeyBelow",
            "getKeyAbove",
            "getKeyLeftOf",
            "getKeyRightOf",
            "getKeyPageBelow",
            "getKeyPageAbove",
            "getFirstKey",
            "getLastKey",
            // HAVE TO ignore this one
            // "getKeyForSearch"
        ].map((name) => [name, () => null]),
    );

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Forms</h1>
            <Spacer y={10} />
            <Tabs>
                <Tab key="registration forms" title="Registration" aria-label="Registration forms tab" >
                    <Table isStriped aria-label="Registration Forms" keyboardDelegate={dummyKeyboardDelegate}>
                        <TableHeader>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Description</TableColumn>
                            <TableColumn>Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {form.registrationForms && (
                                form.registrationForms.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.formName}</TableCell>
                                        <TableCell>{data.formDescription}</TableCell>
                                        <TableCell>{new Date(data.dateCreated.split(" ")[0] + "T00:00:00").toLocaleDateString("en-US")}</TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell>
                                    <Input
                                        id="registrationName"
                                        placeholder="Add new form!"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        id="registrationDescription"
                                        placeholder="Enter description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell  onClick={() => createForm("registration")} style={{ cursor: 'pointer'}}>
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
                <Tab key="judge forms" title="Judging" aria-label="Judge forms tab" >
                    <Table isStriped aria-label="Judge forms" keyboardDelegate={dummyKeyboardDelegate}>
                        <TableHeader>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Description</TableColumn>
                            <TableColumn>Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {form.judgeForms && (
                                form.judgeForms.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.formName}</TableCell>
                                        <TableCell>{data.formDescription}</TableCell>
                                        <TableCell>{new Date(data.dateCreated.split(" ")[0] + "T00:00:00").toLocaleDateString("en-US")}</TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell>
                                    <Input
                                        id="judgeName"
                                        placeholder="Add new form!"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        id="judgeDescription"
                                        placeholder="Enter description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell  onClick={() => createForm("judge")} style={{ cursor: 'pointer'}}>
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
                <Tab key="classList" title="Classes" aria-label="Class lists tab" >
                    <Table
                        isStriped
                        aria-label="Class Lists"
                        keyboardDelegate={dummyKeyboardDelegate}
                    >
                        <TableHeader>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Description</TableColumn>
                            <TableColumn>Date Created</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {form.classLists && (
                                form.classLists.map((data, index) => (
                                    <TableRow
                                        key={index}
                                        style={{ cursor: "pointer"}}
                                        onClick={() => loadList(data.classListId, "class")}
                                    >
                                        <TableCell>{data.classListName}</TableCell>
                                        <TableCell>{data.classListDescription}</TableCell>
                                        <TableCell>{new Date(data.dateCreated.split(" ")[0] + "T00:00:00").toLocaleDateString("en-US")}</TableCell>
                                    </TableRow>
                                ))
                            )}
                            <TableRow>
                                <TableCell>
                                    <Input
                                        key="classListNameEntry"
                                        id="classListName"
                                        ref={newClassListName}
                                        placeholder="Add new class list!"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        key="classListDescriptionEntry"
                                        id="classListDescription"
                                        ref={newClassListDescription}
                                        placeholder="Enter description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                        onClick={(event) => event.stopPropagation()}
                                    />
                                </TableCell>
                                <TableCell  onClick={createClassList} style={{ cursor: 'pointer'}}>
                                    <PlusCircle_6_Blue />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    );
}